<template>
  <div class="conversion-collection-container">
    <div :style="explorerChromeSafari ? 'height: calc(100vh - 19rem) !important;overflow-y: auto;' : 'height: calc(100vh - 13rem) !important;overflow-y: auto;'">
      <!-- 无收货地址 -->
      <div
        style="width:100%;height:100%;"
        class="col-center"
        v-if="collectionList.length == 0"
      >
        <img
          style="width:6.5rem;height:auto;margin-bottom:1.375rem;"
          :src="require('../assets/icon/no-list.png')"
        />
        <p style="font-size: 16px;font-family: lantingheiweight;font-weight: 500;color: #666666;line-height: 22px;">暂无数据</p>
      </div>
      <div
        class="conversion-collection-select-item row-between"
        v-for="(item,index) in collectionList"
        @click="selectConversionCollection(item)"
        :key="index"
      >
        <div class="item-content row-start">
          <img :src="item.image" />
          <div class="col-start">
            <p class="item-content-name">{{item.name}}</p>
            <p class="item-content-unique">ID:{{item.showId && item.showId != '0' ? item.showId : item.uniqueCode}}</p>
          </div>
        </div>
        <img
          style="margin-right:15px;"
          class="donation-select-collection-item-selectimg"
          v-if="selectCollection.id == item.id"
          src="../assets/icon/select-collection.png"
        />
        <img
          style="margin-right:15px;"
          class="donation-select-collection-item-selectimg"
          v-else
          src="../assets/icon/no-select-collection.png"
        />
      </div>
    </div>
    <!-- 新增地址按钮 -->
    <div
      v-if="collectionList.length"
      class="row-center add-adress"
      @click="confireSelect()"
    >确认选择</div>
  </div>
</template>
<script>
import api from '../api/index-client'
export default {
  data () {
    return {
      collectionList: [],
      explorerChromeSafari: false,
      selectCollection: {}
    }
  },
  mounted () {
    this.getCollectionList()
    this.checkUserAgent()
  },
  methods: {
    // 选择藏品
    selectConversionCollection (i) {
      this.selectCollection = i
    },
    // 获取兑换藏品列表
    getCollectionList () {
      api
        .post('assets/exchanges')
        .then(result => {
          if (result.data.success) {
            this.collectionList = result.data.data
            if (this.collectionList.length == 0) {
              this.$toast({
                message: '暂无藏品',
                icon: require('../assets/icon/toast-error.png'),
              })
            }
            this.$store.commit('HIDE_APPLOADING')
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 确认选择操作
    confireSelect () {
      localStorage.setItem('conversionCollection',JSON.stringify(this.selectCollection))
      this.$router.replace('/conversion')
    },
    // 检查设备，适配
    checkUserAgent () {
      var explorer = navigator.userAgent

      if (/(micromessenger)/i.test(explorer)) {
        // 微信浏览器
        this.explorerChromeSafari = false
      } else if (/(MQQBrowser)/i.test(navigator.userAgent)) {
        // qq
        this.explorerChromeSafari = true
      } else if (/(?:Chrome|CriOS)/.test(explorer)) {
        // 谷歌
        this.explorerChromeSafari = true
      } else if (/(iPhone|iPad|iPod|iOS)/i.test(explorer)) {
        // 除上面3个浏览器外的ios设备
        this.explorerChromeSafari = true
      } else {
        // 其他设备
        this.explorerChromeSafari = false
      }
    },
  },
}
</script>
<style lang="less" scoped>
.conversion-collection-container {
  margin-top: 3.875rem;
  height: calc(100vh - 3.875rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;
  .add-adress {
    width: 90vw;
    height: 46px;
    margin: 1.3125rem 5vw 1.4375rem 5vw;
    background-image: url('../assets/icon/confirm-button.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-color: transparent;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.375rem;
    letter-spacing: 2px;
    cursor: pointer;
    background-size: cover;
  }
  .conversion-collection-select-item {
    width: 90vw;
    margin-left: 5vw;
    height: 106px;
    background: #262728;
    border-radius: 8px;
    margin-top: 11px;
    .item-content {
      height: 106px;
      width: 70%;
      margin-left: 11px;
      img {
        height: 80px;
        width: 80px;
        margin-right: 11px;
      }
      .item-content-name {
        font-size: 14px;
        font-family: Noto Sans S Chinese;
        font-weight: 400;
        color: #fefefe;
        margin-bottom: 15px;
      }
      .item-content-unique {
        font-size: 13px;
        font-family: Noto Sans S Chinese;
        font-weight: 400;
        color: #cccccc;
      }
    }
  }
  .donation-select-collection-item-selectimg {
    width: 20px;
    height: 20px;
  }
}
</style>